import * as ReactGA from 'react-ga'
import { getCookieConsentValue, Cookies } from 'react-cookie-consent'

export const initGA = id => {
  if (process.env.NODE_ENV === 'production') {
    console.log('Initialize GA with identifier/key: ', id)
    ReactGA.initialize(id)
  }
}

export const isCookieConsent = () => {
  return getCookieConsentValue('higea-cookie-consent')
}

export const removeAnalyticsCookies = () => {
  //remove google analytics cookies
  Cookies.remove('_ga')
  Cookies.remove('_gat')
  Cookies.remove('_gid')
}
