import { isCookieConsent, removeAnalyticsCookies } from './src/utils/ga-utils'

export const onClientEntry = () => {
  const isConsent = isCookieConsent()
  //   console.log('is consent: ', isConsent)
  //   console.log('GA: ', process.env.GATSBY_GOOGLE_ANALYTICS_ID)
  if (isConsent === undefined || isConsent !== 'true') {
    window[`ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`] = true
    window.doNotTrack == '1'
    removeAnalyticsCookies()
    console.log('Disable GA tracking')
  } else {
    window.doNotTrack == '0'
  }
}
